.HomePage {
    position: relative;
    background-color: #2e050d;
    width: 100vw;
    height: 100vh;
}

.Title {
    font-family: 'Roboto Slab', serif;
    font-size: 1.8em;
    color: rgb(174, 174, 215);
    opacity: 0.4;
    transition: opacity 0.2s;
    cursor: pointer;
    font-weight: 300;
    padding-top: 20px;
    padding-left: 25px;
}

.Title:hover {
    opacity: 1.0;
}

.Selectors {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 500px;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    
}

.SelectedContainer {
    transition: opacity 0.5s;
}

.Selectors > div {
    color: white;
    opacity: 0.5;
    font-family: 'Roboto Slab', serif;
    cursor: pointer;
    transition: opacity 0.2s;
}

.Selectors > div:hover {
    opacity: 1.0;
}